/* CSS reset */

@import "forms";

@font-face {
    font-family: 'FontAwesome';
    font-display: fallback;
    src: url("https://d16ir2fjyhpmd2.cloudfront.net/fonts/fontawesome-webfont.eot"),
         url("https://d16ir2fjyhpmd2.cloudfront.net/fonts/fontawesome-webfont.eot?#iefix") format('embedded-opentype'),
         url("https://d16ir2fjyhpmd2.cloudfront.net/fonts/fontawesome-webfont.woff") format('woff'),
         url("https://d16ir2fjyhpmd2.cloudfront.net/fonts/fontawesome-webfont.ttf") format('truetype'),
         url("https://d16ir2fjyhpmd2.cloudfront.net/fonts/fontawesome-webfont.svg") format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BebasNeueRegular';
    src: url('/css/fonts/BebasNeue-webfont.eot'),
         url('/css/fonts/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'),
         url('/css/fonts/BebasNeue-webfont.woff') format('woff'),
         url('/css/fonts/BebasNeue-webfont.ttf') format('truetype'),
         url('/css/fonts/BebasNeue-webfont.svg#BebasNeueRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('/css/fonts/FuturaBT-Book.ttf') format('truetype'),
         url('/css/fonts/FuturaBT-Book.woff') format('woff'),
         url('/css/fonts/FuturaBT-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td { 
    margin:0;
    padding:0;
}
html,body {
    margin:0;
    padding:0;
    max-width: 100%;
}
address,caption,cite,code,dfn,th,var {
    font-style:normal;
    font-weight:normal;
}
h1,h2,h3,h4,h5,h6 {
    font-size:100%;
    font-weight:normal;
}
body{
    background: #F8F8F8;
    font-weight: 400;
    color: #000;
    overflow-y: scroll;
    overflow-x: hidden;
}
.content-type-home .part-title { 
    font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
    font-size: 11.5vw;
    padding: 0;
    line-height: 11.5vw; 
}
.content-type-page .full-title, .content-type-post .full-title { 
    font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
    font-size: 2.5vw;
    padding: 0;
    line-height: 2.5vw; 
    color: black;
    position: fixed;
}
.content-type-page .full-title:before, .content-type-post .full-title:before {
    font-family: 'FontAwesome';
    font-size: 1.5vw;
    content: '\f060';
    margin-right: 10px;
    vertical-align: middle;
    color: black;
}
.content-type-page .full-title:hover, .content-type-post .full-title:hover {
    text-decoration: none;
    color: black;
}
.content-type-home .full-title {
    display: none;
}
.content-type-page .part-title, .content-type-post .part-title {
    display: none;
}
a:hover,
a:focus {
  color: #e74c3c;
  text-decoration: underline;
  outline: none;
  text-decoration: none;
}
.page, .post {
    margin-top: 3vw;
    padding-left: 3vw;
    padding-right: 3vw;
    display: flex;
    margin-bottom: 20px;
    overflow-x: hidden !important;
    max-width: 100%;
}
#left {
    margin-right: 3.6vw;
    width: 50%;
    box-sizing: border-box;
    display: block;
}
#right {
    font-family: 'Futura';
    font-weight: lighter;
    font-size: 2vw;
    line-height: 3.2vw;
    width: 50%;
    box-sizing: border-box;
    display: block;
}
#content {
    font-family: 'Futura';
    font-weight: lighter;
    font-size: 1.3vw;
    line-height: 1.7vw;
    box-sizing: border-box;
    display: block;
    clear: both;
}
#content h2 {
    font-size: 2.5vw;
    line-height: 2.6vw;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
}
#content ul li {
    margin-left: 2.2vw;
    margin-bottom: 20px;
}
#content ul li a {
    font-weight: bold;
    text-decoration: underline;
}
.clear {
    clear: both;
}
.project {
    margin-bottom: 1vw;
}
.decorated {
    font-size: 3.2vw;
    font-weight: bold;
}
.ie7 body{
    overflow:hidden;
}
a{
    color: #333;
    text-decoration: none;
}
.image {
    margin: 0px;
}
.image img {
    width: 100%;
}
.image figcaption {
    font-size: 0.8rem;
    line-height: 0.9rem;
    color: #555;
}
.image figcaption a {
    text-decoration: underline;
    color: #555;
}
.social-icon {
    font-family: 'FontAwesome';
    line-height: 1.7vw;
    text-align: center;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    font-size: 1.2vw;
    -webkit-font-smoothing: antialiased;
}
.social-facebook .social-icon:before {
    content: "\f39e";
}
.social-twitter .social-icon:before {
    content: "\f099";
}
.social-pinterest .social-icon:before {
    content: "\f231";
}
.social-wordpress .social-icon:before {
    content: "\f19a";
}
.social-instagram .social-icon:before {
    content: "\f16d";
}
.social-linkedin .social-icon:before {
    content: "\f0e1";
}
.social-github .social-icon:before {
    content: "\f09b";
}
.social-medium .social-icon:before {
    content: "\f23a";
}

.content-type-page .social-footer, .content-type-post .social-footer {
    display: none;
}

.content-type-page #right, .content-type-post #right {
    display: none;
}

.social-footer .social-icon-footer-link {
    width: 2.5vw;
    height: 2.5vw;
    display: inline-block;
    vertical-align: middle;
    background-color: #000;
    color: white;
    border-radius: 0.6vw;
    box-sizing: border-box;
    cursor: pointer;
    padding-bottom: 0.1vw;
    padding-top: 0.4vw;
    text-align: center;
}
.social-footer .social-facebook:hover {
    background-color: #3b5998;
    color: white;
}
.social-footer .social-twitter:hover {
    background-color: #00b6f1;
    color: white;
}
.social-footer .social-pinterest:hover {
    background-color: #c91517;
    color: white;
}
.social-footer .social-wordpress:hover {
    background-color: #00749C;
    color: white;
}
.social-footer .social-instagram:hover {
    background-color: #2c6a93;
    color: white;
}
.social-footer .social-linkedin:hover {
    background-color: #0072b1;
    color: white;
}

.social-footer .social-github:hover {
    background-color: #6f42c1;
    color: white;
}

.social-footer .social-medium:hover {
    background-color: #28a745;
    color: white;
}

.container {
    margin: 10px auto;
    width: 100%;
    max-width: 800px;
    text-align: center;
}

.container h1 {
    margin: 30px 0;
    font-size: 3rem;
    line-height: 1;
    letter-spacing: -1px;
}
#content .container h2 {
    margin: 30px 0;
    font-size: 1.7rem;
    line-height: 1;
    letter-spacing: -1px;
    color: #555;
}
#content .container h3 {
    margin: 30px 0;
    font-size: 1.7rem;
    line-height: 2.0rem;
    letter-spacing: -1px;
    text-align: left;
    font-family: 'Futura';
    font-weight: bold;
}
#content .container h5 {
    font-size: 1.2rem;
    line-height: 1.3rem;
    color: #555;
}
#content .container p, #content .container ul, #content .container ul li {
    margin-bottom: 1rem;
    text-align: left;
    font-family: 'Futura';
    font-weight: lighter;
    font-size: 1.5rem;
    line-height: 2.0rem;
}
.post-tags {
    color: #555;
    font-size: 1.1rem;
    line-height: 1.2rem;
}
#content .container p img {
    width: 100%;
}
#content .container blockquote {
    font-style: italic;
    padding-left: 1rem;
    border-left: 2px solid #292929;
    margin: 2rem 0;
}

#content .container blockquote, li, p {
    margin-top: 1rem;
    font-size: 1.3rem;
    line-height: 1.5;
    font-family: 'Futura';
    color: #292929;
}

#footer {
    font-family: 'Futura';
    font-weight: lighter;
    font-size: 1.3rem;
    line-height: 1.5;
    background-color: black;
    color: white;
    padding-top: 0px;
    padding-bottom: 20px;
    clear: both;
    padding: 20px 0 1.75em;
    word-wrap: break-word;
    display: block;
    overflow-x: hidden !important;
    max-width: 100%;
    width: 100%;
}

.footer-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 20px auto 0;
    max-width: 1380px;
    box-sizing: border-box;
    padding: 0 1.75em;
}

.footer-content-area {
    max-width: 740px;
    margin: 0 auto;
    text-align: center;
}

.footer-content-area a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
}

.footer-wrapper a:visited {
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
}

.footer-wrapper a:hover {
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
}

@media screen and (min-width: 1921px) {
    .social-footer .social-icon-footer-link {
        width: calc(1900px * 0.01 * 2.5);
        height: calc(1900px * 0.01 * 2.5);
        border-radius: calc(1900px * 0.01 * 0.6);
        padding-bottom: calc(1900px * 0.01 * 0.1);
        padding-top: calc(1900px * 0.01 * 0.4);
    }
    .social-icon {
        line-height: calc(1900px * 0.01 * 1.7);
        font-size: calc(1900px * 0.01 * 1.2);
    }
    .decorated {
        font-size: calc(1900px * 0.01 * 3.2);
    }
    .project {
        margin-bottom: calc(1900px * 0.01 * 1);
    }
    .page, .post {
        padding-left: calc(1900px * 0.01 * 3);
        padding-right: calc(1900px * 0.01 * 3);
    }
    #left {
        margin-right: calc(1900px * 0.01 * 3.6);
        width: 868px;
    }
    #right {
        font-size: calc(1900px * 0.01 * 2);
        line-height: calc(1900px * 0.01 * 3.2);
        width: 868px;
    }
    #content {
        font-size: calc(1900px * 0.01 * 1.3);
        line-height: calc(1900px * 0.01 * 1.7);
    }
    #content h2 {
        font-size: calc(1900px * 0.01 * 2.5);
        line-height: calc(1900px * 0.01 * 2.6);
        margin-bottom: 20px;
        margin-top: 20px;
    }
    #content ul li {
        margin-left: calc(1900px * 0.01 * 2.2);
        margin-bottom: 20px;
    }
    .content-type-home .part-title { 
        font-size: calc(1900px * 0.01 * 11.5);
        padding: 0;
        line-height: calc(1900px * 0.01 * 11.5); 
    }
    .content-type-page .full-title, .content-type-post .full-title { 
        font-size: calc(1900px * 0.01 * 2.5);
        line-height: calc(1900px * 0.01 * 2.5);
    }
    .content-type-page .full-title:before, .content-type-post .full-title:before {
        font-size: calc(1900px * 0.01 * 1.5);
        margin-right: 10px;
    }
}

@media screen and (max-width: 1440px) {
    #content {
        font-size: 1.7vw;
        line-height: 2.5vw;
        width: 100%;
    }
    #content h2 {
        font-size: 2.5vw;
        line-height: 2.7vw;
    }
    #content ul li {
        margin-left: 2.5vw;
        margin-bottom: 10px;
    }
    .content-type-page .full-title, .content-type-post .full-title { 
        font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
        font-size: 2.5vw;
        padding: 0;
        line-height: 2.5vw; 
        color: black;
        position: initial;
    }
}

@media screen and (max-width: 1112px) {
    .content-type-home .part-title { 
        font-size: 18vw;
        line-height: 18vw; 
    }
    .content-type-page .full-title, .content-type-post .full-title { 
        font-size: calc(1900px * 0.01 * 2.6);
        line-height: calc(1900px * 0.01 * 2.6);
    }
    .content-type-page .full-title:before, .content-type-post .full-title:before {
        font-size: calc(1900px * 0.01 * 1.6);
        margin-right: 10px;
    }
    .page, .post {
        padding-left: 3vw;
        padding-right: 3vw;
        display: block;
    }
    .social-footer .social-icon-footer-link {
        width: 3.5vw;
        height: 3.5vw;
    }
    .social-icon {
        line-height: 3vw;
        font-size: 2.2vw;
    }
    .decorated {
        font-size: 5.2vw;
    }
    #left {
        margin-bottom: 4vw;
        width: 100%;
    }
    #right {
        display: none;
    }
    #content {
        font-size: 1.7vw;
        line-height: 2.5vw;
        width: 100%;
    }
    #content h2 {
        font-size: 2.5vw;
        line-height: 2.7vw;
    }
    #content ul li {
        margin-left: 2.5vw;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 896px) {
    #content {
        font-size: 4.0vw;
        line-height: 5.0vw;
    }
    #content h2 {
        font-size: 5.0vw;
        line-height: 5.2vw;
    }
    #content ul li {
        margin-left: 5.2vw;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 768px) {
    .content-type-home .part-title { 
        font-size: 24vw;
        line-height: 24vw; 
    }
    .content-type-page .full-title, .content-type-post .full-title { 
        font-size: calc(1900px * 0.01 * 2.7);
        line-height: calc(1900px * 0.01 * 2.7);
    }
    .content-type-page .full-title:before, .content-type-post .full-title:before {
        font-size: calc(1900px * 0.01 * 1.7);
        margin-right: 10px;
    }
    .social-footer .social-icon-footer-link {
        width: 6.5vw;
        height: 6.5vw;
    }
    .social-icon {
        line-height: 5.9vw;
        font-size: 4.2vw;
    }
    .project {
        margin-bottom: 3vw;
    }
    .decorated {
        font-size: 10.2vw;
    }
    #right {
        display: none;
    }
    #content {
        font-size: 3.4vw;
        line-height: 4.0vw;
    }
    #content h2 {
        font-size: 5.0vw;
        line-height: 5.2vw;
    }
    #content ul li {
        margin-left: 5.2vw;
    }
}

@media screen and (max-width: 640px) {
    .content-type-home .part-title { 
        font-size: 24vw;
        line-height: 24vw; 
    }
    .content-type-page .full-title, .content-type-post .full-title { 
        font-size: calc(1900px * 0.01 * 2.8);
        line-height: calc(1900px * 0.01 * 2.8);
    }
    .content-type-page .full-title:before, .content-type-post .full-title:before {
        font-size: calc(1900px * 0.01 * 1.8);
        margin-right: 10px;
    }
    .social-footer .social-icon-footer-link {
        width: 6.5vw;
        height: 6.5vw;
    }
    .social-icon {
        line-height: 5.9vw;
        font-size: 4.2vw;
    }
    .project {
        margin-bottom: 3vw;
    }
    .decorated {
        font-size: 10.2vw;
    }
    #right {
        display: none;
    }
    #content {
        font-size: 3.4vw;
        line-height: 4.0vw;
    }
    #content h2 {
        font-size: 5.0vw;
        line-height: 5.2vw;
    }
    #content ul li {
        margin-left: 5.2vw;
    }
}

@media screen and (max-width: 414px) {
    #content {
        font-size: 4.0vw;
        line-height: 5.0vw;
    }
    #content h2 {
        font-size: 5.0vw;
        line-height: 5.2vw;
    }
    #content ul li {
        margin-left: 5.2vw;
    }
}

@media screen and (max-width: 360px) {
    #content {
        font-size: 4.5vw;
        line-height: 5.5vw;
    }
    #content h2 {
        font-size: 5.5vw;
        line-height: 5.7vw;
    }
    #content ul li {
        margin-left: 5.5vw;
    }
}

@media screen and (max-width: 240px) {
    #content {
        font-size: 5.5vw;
        line-height: 6.5vw;
    }
    #content h2 {
        font-size: 6.5vw;
        line-height: 6.7vw;
    }
    #content ul li {
        margin-left: 5.5vw;
    }
}

